<template>
  <!-- 這頁是所有 fb 免註冊相關頁面的最外層，就像 app.vue 一樣(目前已停用) -->
  <div>
    <div
      class="tw-bg-main d-flex justify-content-center align-items-center"
      style="height: 100vh"
      v-if="!show"
    >
      <p
        class="alert alert-danger fw-bolder"
        v-if="text === '請稍後~正在為您準備資料中...'"
      >
        {{ text }}
      </p>
      <p class="alert alert-primary fw-bolder" v-else>{{ text }}</p>
    </div>
    <router-view v-if="show"></router-view>
  </div>
</template>

<script>
// vuex
import { mapState } from "vuex";
// api
import { parseToken, sortoutParseTokenResult } from '@/methods/API/parseToken.js'
// mixin
import { GetStoreList } from '@/methods/API/getStoreList'

export default {
  data() {
    return {
      show: false,
      fb_serverToken: '',
      // FB 免註冊用戶 (虛擬顧客)
      fbStores: {
        loading: false,
        data: []
      },
      text: '請稍後~正在為您準備資料中...'
    }
  },
  mixins: [GetStoreList],
  created() {
    console.log('fb app')
    this.fb_serverToken = this.$methods.getCookie('fb_serverToken')
    this.getFbUserInfo()
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
      customerStores: state => state.customerStores,
      fbStores: state => state.fbStores
    })
  },
  methods: {
    // 取得 FB 用戶資訊
    getFbUserInfo() {
      // 解析 FB 用戶的 token
      const result = parseToken(this.fb_serverToken)
      result.then(res => {
        console.log(res)
        const parseTokenResult = sortoutParseTokenResult(res)
        // 檢查 FB 用戶為實體或虛擬顧客
        this.checkFbUserType(parseTokenResult)
      }).catch(err => {
        console.log(err)
        this.SweetAlert('other', err.responseJSON.message)
      })
    },
    // 檢查 FB 用戶為實體或虛擬顧客
    checkFbUserType(parseTokenResult) {
      if (!parseTokenResult.userType) {
        // 代表尚未加入過任何賣場
        this.text = '您已成功授權 FB，目前您尚未加入任何賣場，趕緊前往下單 ^^'
      } else {
        switch (parseTokenResult.userType) {
          case 1:
            // 實體顧客
            this.getPhysicalUserInfoForStoreList(parseTokenResult, this.fb_serverToken)
            break;
          case 4:
            // 虛擬顧客
            this.getStoresWithVirtualUser(parseTokenResult)
            break;
        }
        this.getD4StoreId()
      }
    },
    // 用虛擬用戶取得所有賣場
    getStoresWithVirtualUser(parseTokenResult) {
      const vm = this
      const getStoresWithVirtualUser = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.fb_serverToken
      }
      const virtualUserIds = parseTokenResult.id.split(',')
      const data = [
        {
          query: {"columns":[{"name":"id"}],"group":{"groups":[{"column":{"name":"UserType"},"operator":{"operator":"=","type":1,"value":`${parseTokenResult.userType}`}},{"column":{"name":"UserId"},"operator":{"type":3,"value":virtualUserIds}}],"operator":1},"siteObjectType":13,"sqlCommandType":1},
          methods: '{getStore{getCurrentSubscriptionProject}}'
        }
      ]
      this.fbStores = {
        loading: false,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoresWithVirtualUser,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participants = res.data[0].objects
            vm.sortoutStores(participants)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 整理虛擬顧客的賣場
    sortoutStores(participants) {
      const now = new Date() * 1
      participants.forEach(item => {
        // 判斷有沒有符合購買方案的時間區間內
        let is_inSubscription = false
        if (item.store.currentSubscriptionProject) {
          const enableTime = item.store.currentSubscriptionProject.enableTime
          const expirationTime = item.store.currentSubscriptionProject.expirationTime
          is_inSubscription = enableTime <= now && now <= expirationTime
        }
        // 顧客少了 verify 的判斷
        if (item.authority !== 'COADMIN' && !item.deleted && !item.store.deleted && is_inSubscription) {
          this.fbStores.data.push(item)
        }
      })
      this.fbStores.loading = false
      this.$store.dispatch('StoreList/setStoreList', {
        seller: null,
        customer: null,
        fb: this.fbStores
      })
    },
    // 取得預設 storeId
    getD4StoreId() {
      const checkPoint = setInterval(() => {
        if (!this.fbStores.loading && this.fbStores.data.length > 0) {
          if (!this.$route.params.storeId) {
            // 第一次進入而非重新整理
            this.$router.push({
              name: 'Fb_MerchOrder',
              params: {
                storeId: this.fbStores.data[0].storeId
              }
            })
          } else {
            // 重新整理
            const matchStoreId = this.fbStores.data.some(item => {
              if (item.storeId == this.$route.params.storeId) {
                this.$router.push({
                  name: 'Fb_MerchOrder',
                  params: {
                    storeId: item.storeId
                  }
                })
                return true
              }
            })
            if (!matchStoreId) {
              alert('您尚未有權限進入此賣場')
              this.$router.push({
              name: 'Fb_MerchOrder',
                params: {
                  storeId: this.fbStores.data[0].storeId
                }
              })
            }
          }
          this.show = true
        } else {
          this.text = '您目前所在的賣場已全數關閉'
        }
        clearInterval(checkPoint)
      }, 500)
    },
  },
}
</script>